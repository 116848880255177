.cyberskiller-toast {
  display: block;
  width: auto;
  height: auto;
  z-index: 1002;
  margin: map-get($paddings, minified);
  box-shadow: 0 0 20px rgba(map-get($theme, hblack), 0.25);
  border: 1px solid rgba(map-get($theme, hwhite), 0.5);

  &.toast-info,
  &.toast-warning,
  &.toast-success,
  &.toast-error {
    background: transparent;
    background-image: none;
  }
}
