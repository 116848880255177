@forward "design-system/styles";

@import "scss/base/all";
@import "scss/fonts/all";
@import "scss/utilities/all";
@import "scss/layout/all";

@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities";

@import "ngx-toastr/toastr";

@import url("https://fonts.googleapis.com/css?family=Cousine&display=swap");

@import '@angular/cdk/overlay-prebuilt.css';

/* Global */
html,
body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  background-color: map-get($theme, hgray);
  background-image: url("/assets/images/backgrounds/bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  &,
  & *,
  &:before,
  &:after,
  & *:before,
  & *:after {
    box-sizing: border-box;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}
