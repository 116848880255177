@use "src/design-system/styles/variables";

.dropdown-anchor {
  display: flex;
  padding: map-get($paddings, standard);
  color: map-get($theme, hwhite);
  background-color: darken(map-get($theme, hprimarydark), 10);
  transition:
    color $transition-time linear,
    background-color $transition-time linear;
  font-size: 1.5rem;
  cursor: pointer;

  .typcn {
    margin-right: 5px;
    font-size: 0.9em;
    line-height: 1em;
  }

  &:hover {
    color: map-get($theme, hwhite);
    background-color: map-get($theme, hprimary);
  }

  &:active {
    box-shadow: 4px 4px 20px rgba(map-get($theme, hblack), 0.1) inset;
  }
}

.dropdown-anchor-light {
  background-color: variables.$white;
  color: variables.$primary-700;
}

.dropdown-anchor-rounded {
  border-radius: variables.$border-radius-default;
}
