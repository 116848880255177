$font-size-10: 1rem;
$font-size-12: 1.2rem;
$font-size-14: 1.4rem;
$font-size-15: 1.5rem;
$font-size-16: 1.6rem;
$font-size-18: 1.8rem;
$font-size-20: 2rem;
$font-size-23: 2.3rem;
$font-size-36: 3.6rem;

$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;

$line-height-15: 1.5rem;
$line-height-18: 1.8rem;
$line-height-20: 2rem;
$line-height-24: 2.4rem;
$line-height-28: 2.8rem;
$line-height-30: 3rem;
