@use "../variables/typography";

@mixin label() {
  text-transform: uppercase;
  font-weight: typography.$font-weight-500;
  font-size: typography.$font-size-15;

  &.normal-case {
    text-transform: none;
  }
}

@mixin heading-4 {
  font-size: typography.$font-size-23;
  font-weight: typography.$font-weight-600;
  line-height: typography.$line-height-30;
}

@mixin heading-6 {
  font-size: typography.$font-size-16;
  font-weight: typography.$font-weight-600;
  line-height: typography.$line-height-24;
}

@mixin tooltip {
  font-size: typography.$font-size-10;
  line-height: typography.$line-height-18;
  font-weight: typography.$font-weight-600;
}

@mixin placeholder {
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-20;
  font-weight: typography.$font-weight-400;
}
