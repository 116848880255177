.table {
  table-layout: auto;
  border-spacing: 1px;
  border-collapse: separate;

  th,
  td {
    height: 30px;
    white-space: nowrap;
    padding-left: map-get($paddings, minified);
    padding-right: map-get($paddings, minified);
    border: 1px solid map-get($theme, hlightgray);
    font-weight: normal;
    transition-property: background-color, color, border-color;
    transition-duration: $transition-time;
    transition-timing-function: linear;

    &.one-and-half {
      height: 46px;
    }
  }

  thead {
    th {
      text-align: left;
    }
  }

  tbody {
    tr:nth-child(2n + 1) {
      td {
        background-color: lighten(map-get($theme, hlightgray), 10);
      }
    }

    tr {
      &.highlight {
        td {
          background-color: map-get($theme, hhighlight);
        }
      }
    }
  }
}
