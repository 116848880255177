.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  & > iframe,
  & > .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.exercise-description {
  .video-responsive {
    max-width: 1280px;
    height: auto;
    aspect-ratio: 16 / 9;
    padding-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
