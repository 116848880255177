@use "sass:math";

$theme: (
  hlogo: #00b7ee,
  hprimary: #247de2,
  hprimary-700: #415ebe,
  hprimarydark: #2d4285,
  hprimarylight: #dae8fb,
  hprimarytransparency20: rgba(45, 66, 133, 0.2),
  hsecondary: #039855,
  hsecondarydark: #387400,
  hsecondarylight: #97e27f,
  hcompleted: #05ff00,
  hgray: #1e1e1c,
  hwhite: #ffffff,
  hwhitedrity: #fcfcfd,
  hblack: #111111,
  hlabelgray: #111111,
  hannotationgray: rgb(151, 151, 151),
  hlightgray: #d8d8d8,
  hdarkgray: #666666,
  hbackgroundgray: #e7eef7,
  hdarkpanel: #354963,
  htransparent: rgba(255, 255, 255, 0),
  hinvalid: #b43434,
  hinvalidlight: #e69898,
  hwarning: #e79e00,
  hwarningdark: #af7800,
  hhighlight: #ffe60080,
  hdifficulty: rgb(255, 145, 0),
  hfooterbg: #dce6ff,
  herror: #d92d20
);

// Inverted versions
@each $color-name, $color in $theme {
  $theme: map-merge($theme, (#{$color-name}-inversed: invert($color)));
}

$weights: 800 700 600 500 400 300 200 100;
$containers: (
  hcontainer-extended: var(--hcontainer-extended),
  hcontainer-standard: var(--hcontainer-standard),
  hcontainer-box: var(--hcontainer-box),
  hcontainer-small-box: var(--hcontainer-small-box),
  hcontainer-mobile-box: var(--hcontainer-mobile-box),
  hcontainer-wide: 100%,
);
$paddings: (
  standard: 20px,
);
$paddings: map-merge(
  $paddings,
  (
    extended: map-get($paddings, standard) * 2,
  )
);
$paddings: map-merge(
  $paddings,
  (
    minified: math.div(map-get($paddings, standard), 2),
  )
);
$compensation-margin: 36px;
$transition-time: 0.15s;
$mobile-breakpoint: sm;
$font: "K2D";
$navbar-height: 70px;
$ide-statusbar-height: 20px;
