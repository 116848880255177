@use "src/design-system/styles/utils/typography";

@mixin heading-font(
  $size,
  $weight: 600,
  $uppercase: "false",
  $nomargin: "true"
) {
  font-size: $size;
  font-weight: $weight;
  @if ($uppercase == "true") {
    text-transform: uppercase;
  }

  @if ($nomargin == "true") {
    margin: 0;
  }

  small,
  .prefix {
    display: block;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.5em;
    line-height: 0.9em;
  }

  @include media-breakpoint-down(xs) {
    font-size: $size - 0.5;
  }
}

html {
  font-size: 62.5%;
}

body {
  &,
  input,
  textarea,
  select,
  button {
    font-family: $font;
  }

  h1,
  .heading-1 {
    @include heading-font(4rem, 500);
  }
  h2,
  .heading-2 {
    @include heading-font(3.5rem, 300);
  }
  h3,
  .heading-3 {
    @include heading-font(3rem, 500);
  }
  h4,
  .heading-4 {
    @include heading-font(2.3rem, 600);
  }
  h5,
  .heading-5 {
    @include heading-font(2rem, 600);
  }
  h6,
  .heading-6 {
    @include heading-font(1.7rem, 600);
  }

  p,
  .paragraph,
  .rich-text {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 400;
  }

  .paragraph-small {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  label,
  .label {
    @include typography.label;
  }

  .mini-label {
    font-size: 1rem;
    line-height: 0.8rem;
  }

  @each $weight in $weights {
    .font-weight-#{$weight} {
      font-weight: $weight;
    }
  }

  a {
    text-decoration: none;
    color: map-get($theme, hblack);
  }

  pre,
  .code {
    font-family: "Roboto Mono";
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0em;
    color: map-get($theme, hblack);
    overflow: auto;
    padding-bottom: 2rem;
  }

  .rich-text {
    h1,
    h2,
    h3 {
      margin-bottom: 12px;
    }

    h4,
    h5,
    h6 {
      margin-top: 19px;
      margin-bottom: 12px;
    }

    li {
      font-size: 1.8rem;
      font-weight: 400;
    }

    ul,
    p {
      margin: 10px 0;
    }

    a {
      color: map-get($theme, hprimarydark);
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-down(sm) {
        word-break: break-all;
      }
    }

    img:not(.d-inline-block),
    iframe {
      display: block;
      margin: 5px auto 15px;
      max-width: 100%;
    }

    img.d-inline-block {
      vertical-align: middle;
    }
  }
}
