@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Bold.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Bold.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Bold.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-ExtraLight.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLight.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLight.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-BoldItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-BoldItalic.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-BoldItalic.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-BoldItalic.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Medium.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Medium.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Medium.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-ExtraLightItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLightItalic.woff2")
      format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLightItalic.woff")
      format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-ExtraLightItalic.ttf")
      format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-LightItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-LightItalic.woff2")
      format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-LightItalic.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Light.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Light.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Light.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Light.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Italic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Italic.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Italic.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-SemiBoldItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff2")
      format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBoldItalic.woff")
      format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBoldItalic.ttf")
      format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-ThinItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-ThinItalic.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-ThinItalic.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-ThinItalic.ttf")
      format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-MediumItalic.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-MediumItalic.woff2")
      format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-MediumItalic.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-MediumItalic.ttf")
      format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Thin.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Thin.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Thin.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-Regular.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-Regular.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-Regular.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/roboto-mono/RobotoMono-SemiBold.eot");
  src:
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBold.woff") format("woff"),
    url("/assets/fonts/roboto-mono/RobotoMono-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
