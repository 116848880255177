@keyframes safe-background-move {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100px 100px;
  }
}

@keyframes playground-anchor {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 250px 250px;
  }
}

@keyframes nav-border-flow {
  0% {
    border-radius: 50%;
  }

  20% {
    border-radius: 0%;
    border-style: dashed;
  }

  85% {
    border-radius: 0%;
  }

  100% {
    border-style: dashed;
    border-width: 1px;
    border-radius: 50%;
  }
}

@keyframes nav-radius-flow {
  0% {
    border-radius: 50%;
  }

  20% {
    border-radius: 0%;
  }

  85% {
    border-radius: 0%;
  }

  100% {
    border-radius: 50%;
  }
}

@keyframes spinner-pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  50% {
    transform: scale(0.2);
    opacity: 0.9;
  }

  100% {
    transform: scale(1);
    border-radius: 50%;
  }
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  80% {
    transform: rotateZ(360deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bell-swing {
  0% {
    transform: rotateZ(-10deg);
  }

  100% {
    transform: rotateZ(10deg);
  }
}

@keyframes added {
  0% {
    background-color: map-get($theme, hsecondarylight);
  }
}

@keyframes deleted {
  0% {
    background-color: map-get($theme, hinvalidlight);
  }
}

@keyframes highlighted {
  0% {
    background-color: map-get($theme, hhighlight);
  }
}

@keyframes movingY {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-4px);
  }
}

@keyframes slideInY {
  0% {
    transform-origin: center top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
