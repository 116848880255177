$border-radius-default: 2rem;
$border-radius-input: 8px;

$padding-input-x: 14px;
$padding-input-y: 10px;
$padding-icon-input-left: 42px;
$padding-listbox-y: 16px;
$padding-listbox-x: 24px;

$padding-tile-x: 30px;
$padding-tile-y: 30px;

$padding-ide-x: 40px;

$min-height-listbox: 72px;

$checkbox-size: 24px;
$tooltip-padding: 12px;
$tooltip-radius: 8px;

$exercise-button-margin: 1rem;

$container-tab-gap: 8px;
$container-tab-size: 48px;
$container-tab-radius: 6px;
$container-tab-icon: 22px;
$container-sidebar: 72px;

$navbar-height: 70px;
$mobile-breakpoint: sm;
$size-12: 1.2rem;

$container-box-gap: 20px;

$container-card-size: 473px;
$container-card-max-size: 387px;
$container-card-gap: 35px;
$container-card-margin: 55px;
$container-card-height: 282px;

$chip-paddings: 2px 8px 2px 8px;
