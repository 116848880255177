@use "./utils";

$rotates: (
  "90": 90deg,
  "45": 45deg,
  "180": 180deg,
  "270": 270deg,
);

@each $name, $angle in $rotates {
  .rotate-#{$name}:not(.uil) {
    @include utils.rotate($angle: $angle);
  }
}
