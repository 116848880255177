$primary-25: #fcfcfe;
$primary-50: #f9fafd;
$primary-100: #f2f4fb;
$primary-200: #e6eafe;
$primary-300: #d3d9f0;
$primary-400: #93a4da;
$primary-500: #7489cf;
$primary-600: #546ec4;
$primary-700: #415ebe;
$primary-900: #2d4285;

$success-25: #f6fef9;
$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-400: #32d583;
$success-500: #12b76a;
$success-600: #039855;

$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-900: #7a2e0e;

$error-25: #fffbfa;
$error-50: #fef3f2;
$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;
$error-400: #f97066;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;

$gray-25: #fcfcfd;
$gray-30: #fafafa;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-900: #101828;

$blue-gray-400: #717bbc;
$blue-gray-500: #4e5ba6;
$blue-gray-600: #3e4784;
$blue-gray-800: #293056;

$blue-light-100: #e3f9ff;
$blue-light-300: #a5eaff;
$blue-light-500: #36d0ff;
$blue-light-900: #00b8ee;

$gradient-color-600: #317BB6;
$gradient-color-700: #306EAB;
$gradient-color-800: #2E5394;

$black: #000000;
$white: #ffffff;

$button-lg-shadow: rgba(16, 24, 40, 0.05);

$input-focus: #f4ebff;
$input-shadow: rgba(16, 24, 40, 0.05);

$listbox-shadow: rgba(16, 24, 40, 0.1);

$navbar-shadow: rgba(0, 0, 0, 0.4);
$tooltip-shadow: rgba(16, 24, 40, 0.08);
