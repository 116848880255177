.lm_tab {
    &.lm_result:not(.lm_active) {
        .lm_title {
            &:after {
                content: ' ';
                display: inline-block;
                vertical-align: middle;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: map-get($theme, hprimary);
                margin-left: 4px;
            }
        }
    }
}