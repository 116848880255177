@use "../variables";
@use "sass:map";

@mixin input-placeholder {
  opacity: 1;
  color: variables.$gray-500;
  font-weight: variables.$font-weight-400;
}

@mixin input-focus {
  border-color: variables.$primary-300;
  outline: 4px solid variables.$input-focus;
}

@mixin input-disabled {
  color: variables.$gray-500;
  border-color: variables.$gray-300;
  background-color: variables.$gray-50;
}

@mixin button-disabled($disabled-color: variables.$gray-25, $text-color: variables.$white) {
  background-color: $disabled-color;
  color: $text-color;
  box-shadow: none;
  cursor: not-allowed;
}

@mixin button($button-color-scheme) {
  $default-scheme: (
    "hover-background-color": variables.$gray-25,
    "text-color": variables.$white,
    "disabled-color": variables.$gray-25,
    "disabled-text-color": variables.$white,
  );

  $color-scheme: map.merge($default-scheme, $button-color-scheme);

  color: map.get($color-scheme, "text-color");
  background-color: map.get($color-scheme, "background-color");

  &:hover:not(:disabled) {
    color: map.get($color-scheme, "hover-text-color");
    background-color: map.get($color-scheme, "hover-background-color");
    border-color: map.get($color-scheme, "background-color");
  }

  &:disabled {
    @include button-disabled(map.get($color-scheme, "disabled-color"), map.get($color-scheme, "disabled-text-color"));
  }
}
