body {
  .hcontainer {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @each $container, $length in $containers {
      &.#{$container} {
        max-width: $length;
      }
    }
  }
}
