.modal {
  .modal-title {
    font-weight: 600;
  }

  .modal-text {
    margin-bottom: map-get($paddings, standard);
  }

  .modal-buttons {
    margin-top: map-get($paddings, minified);

    .modal-button {
      margin-right: 5px;
    }
  }
}
