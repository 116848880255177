// Used for generate color classes only
@each $color-name, $color in $theme {
  .color-#{$color-name} {
    color: $color;
  }

  .background-color-#{$color-name} {
    background-color: $color;
  }
}
